import Menu from "~~/lib/types/Menu";

export const getHeaderMenu = (): Menu[] => {
  return [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "Destinations",
      href: "/destinations",
      children: [
        {
          name: "Africa",
          href: "/africa",
          children: [
            {
              name: "Benin",
              href: "/benin",
            },
            {
              name: "Botswana",
              href: "/botswana",
            },
            {
              name: "Comoros Island",
              href: "/comoros",
            },
            {
              name: "Egypt",
              href: "/egypt",
            },
            {
              name: "Eswatini (Swaziland)",
              href: "/swaziland",
            },
            {
              name: "Ghana",
              href: "/ghana",
            },
            {
              name: "Kenya",
              href: "/kenya",
            },
            {
              name: "Lesotho",
              href: "/lesotho",
            },
            {
              name: "Madagascar",
              href: "/madagascar",
            },
            {
              name: "Malawi",
              href: "/malawi",
            },
            {
              name: "Morocco",
              href: "/morocco",
            },
            {
              name: "Namibia",
              href: "/namibia",
            },
            {
              name: "Rwanda",
              href: "/rwanda",
            },
            {
              name: "Senegal",
              href: "/senegal",
            },
            {
              name: "South Africa",
              href: "/south-africa",
            },
            {
              name: "Tanzania",
              href: "/tanzania",
            },
            {
              name: "The Gambia",
              href: "/gambia",
            },
            {
              name: "Togo",
              href: "/togo",
            },
            {
              name: "Tunisia",
              href: "/tunisia",
            },
            {
              name: "Uganda",
              href: "/uganda",
            },
            {
              name: "Zambia",
              href: "/zambia",
            },
            {
              name: "Zanzibar",
              href: "/africa/zanzibar",
            },
            {
              name: "Zimbabwe",
              href: "/zimbabwe",
            },
          ],
        },
        {
          name: "Asia",
          href: "/asia",
          children: [
            {
              name: "Armenia",
              href: "/armenia",
            },
            {
              name: "Azerbaijan",
              href: "/azerbaijan",
            },
            {
              name: "Bali",
              href: "/bali",
            },
            {
              name: "Bhutan",
              href: "/bhutan",
            },
            {
              name: "Borneo",
              href: "/borneo",
            },
            {
              name: "Cambodia",
              href: "/cambodia",
            },
            {
              name: "China",
              href: "/china",
            },
            {
              name: "Georgia",
              href: "/georgia",
            },
            {
              name: "Hong Kong",
              href: "/hong-kong",
            },
            {
              name: "India",
              href: "/india",
            },
            {
              name: "Indonesia",
              href: "/indonesia",
            },
            {
              name: "Japan",
              href: "/japan",
            },
            {
              name: "Kazakhstan",
              href: "/kazakhstan",
            },
            {
              name: "Kyrgyzstan",
              href: "/kyrgyzstan",
            },
            {
              name: "Laos",
              href: "/laos",
            },
            {
              name: "Malaysia",
              href: "/malaysia",
            },
            {
              name: "Mongolia",
              href: "/mongolia",
            },
            {
              name: "Nepal",
              href: "/nepal",
            },
            {
              name: "Pakistan",
              href: "/pakistan",
            },
            {
              name: "Philippines",
              href: "/philippines",
            },
            {
              name: "Singapore",
              href: "/singapore",
            },
            {
              name: "South Korea",
              href: "/south-korea",
            },
            {
              name: "Sri Lanka",
              href: "/sri-lanka",
            },
            {
              name: "Taiwan",
              href: "/taiwan",
            },
            {
              name: "Tajikistan",
              href: "/tajikistan",
            },
            {
              name: "Thailand",
              href: "/thailand",
            },
            {
              name: "Tibet",
              href: "/tibet",
            },
            {
              name: "Timor-Leste",
              href: "/timor-leste",
            },
            {
              name: "Turkmenistan",
              href: "/turkmenistan",
            },
            {
              name: "Uzbekistan",
              href: "/uzbekistan",
            },
            {
              name: "Vietnam",
              href: "/vietnam",
            },
          ],
        },
        {
          name: "Australia & Oceania",
          href: "/oceania",
          children: [
            {
              name: "Australia",
              href: "/australia",
            },
            {
              name: "- New South Wales",
              href: "/australia/new-south-wales",
            },
            {
              name: "- Northern Territory",
              href: "/australia/northern-territory",
            },
            {
              name: "- Queensland",
              href: "/australia/queensland",
            },
            {
              name: "- South Australia",
              href: "/australia/south-australia",
            },
            {
              name: "- Tasmania",
              href: "/australia/tasmania",
            },
            {
              name: "- Uluru",
              href: "/australia/uluru",
            },
            {
              name: "- Victoria",
              href: "/australia/victoria",
            },
            {
              name: "- Western Australia",
              href: "/australia/western-australia",
            },
            {
              name: "Fiji",
              href: "/fiji",
            },
            {
              name: "New Zealand",
              href: "/new-zealand",
            },
            {
              name: "Papua New Guinea",
              href: "/papua-new-guinea",
            },
            {
              name: "Samoa",
              href: "/samoa",
            },
            {
              name: "Vanuatu",
              href: "/vanuatu",
            },
          ],
        },
        {
          name: "Central America",
          href: "/central-america",
          children: [
            {
              name: "Belize",
              href: "/belize",
            },
            {
              name: "Caribbean",
              href: "/caribbean",
            },
            {
              name: "Costa Rica",
              href: "/costa-rica",
            },
            {
              name: "Cuba",
              href: "/cuba",
            },
            {
              name: "El Salvador",
              href: "/el-salvador",
            },
            {
              name: "Guatemala",
              href: "/guatemala",
            },
            {
              name: "Honduras",
              href: "/honduras",
            },
            {
              name: "Mexico",
              href: "/mexico",
            },
            {
              name: "Nicaragua",
              href: "/nicaragua",
            },
            {
              name: "Panama",
              href: "/panama",
            },
          ],
        },
        {
          name: "Europe",
          href: "/europe",
          children: [
            {
              name: "Albania",
              href: "/albania",
            },
            {
              name: "Andorra",
              href: "/andorra",
            },
            {
              name: "Austria",
              href: "/austria",
            },
            {
              name: "Azerbaijan",
              href: "/azerbaijan",
            },
            {
              name: "Belarus",
              href: "/belarus",
            },
            {
              name: "Belgium",
              href: "/belgium",
            },
            {
              name: "Bosnia",
              href: "/bosnia-and-herzegovina",
            },
            {
              name: "Bulgaria",
              href: "/bulgaria",
            },
            {
              name: "Croatia",
              href: "/croatia",
            },
            {
              name: "Cyprus",
              href: "/cyprus",
            },
            {
              name: "Czech Republic",
              href: "/czech-republic",
            },
            {
              name: "Denmark",
              href: "/denmark",
            },
            {
              name: "Estonia",
              href: "/estonia",
            },
            {
              name: "Finland",
              href: "/finland",
            },
            {
              name: "France",
              href: "/france",
            },
            {
              name: "Georgia",
              href: "/georgia",
            },
            {
              name: "Germany",
              href: "/germany",
            },
            {
              name: "Greece",
              href: "/greece",
            },
            {
              name: "Greenland",
              href: "/greenland",
            },
            {
              name: "Hungary",
              href: "/hungary",
            },
            {
              name: "Iceland",
              href: "/iceland",
            },
            {
              name: "Ireland",
              href: "/ireland",
            },
            {
              name: "Italy",
              href: "/italy",
            },
            {
              name: "Kosovo",
              href: "/kosovo",
            },
            {
              name: "Latvia",
              href: "/latvia",
            },
            {
              name: "Lithuania",
              href: "/lithuania",
            },
            {
              name: "Luxembourg",
              href: "/luxembourg",
            },
            {
              name: "Malta",
              href: "/malta",
            },
            {
              name: "Montenegro",
              href: "/montenegro",
            },
            {
              name: "Netherlands",
              href: "/netherlands",
            },
            {
              name: "North Macedonia",
              href: "/macedonia",
            },
            {
              name: "Norway",
              href: "/norway",
            },
            {
              name: "Poland",
              href: "/poland",
            },
            {
              name: "Portugal",
              href: "/portugal",
            },
            {
              name: "Romania",
              href: "/romania",
            },
            {
              name: "Scotland",
              href: "/scotland",
            },
            {
              name: "Serbia",
              href: "/serbia",
            },
            {
              name: "Slovakia",
              href: "/slovakia",
            },
            {
              name: "Slovenia",
              href: "/slovenia",
            },
            {
              name: "Spain",
              href: "/spain",
            },
            {
              name: "Sweden",
              href: "/sweden",
            },
            {
              name: "Switzerland",
              href: "/switzerland",
            },
            {
              name: "Turkey",
              href: "/turkey",
            },
            {
              name: "United Kingdom",
              href: "/united-kingdom",
            },
          ],
        },
        {
          name: "Middle East",
          href: "/middle-east",
          children: [
            {
              name: "Egypt",
              href: "/egypt",
            },
            {
              name: "Jordan",
              href: "/jordan",
            },
            {
              name: "Oman",
              href: "/oman",
            },
            {
              name: "Saudi Arabia",
              href: "/saudi-arabia",
            },
            {
              name: "Turkey",
              href: "/turkey",
            },
          ],
        },
        {
          name: "North America",
          href: "/north-america",
          children: [
            {
              name: "Canada",
              href: "/canada",
            },
            {
              name: "Mexico",
              href: "/mexico",
            },
            {
              name: "USA",
              href: "/united-states",
            },
            {
              name: "- Alaska",
              href: "/united-states/alaska",
            },
            {
              name: "- California",
              href: "/united-states/california",
            },
            {
              name: "- National parks",
              href: "/united-states/national-parks",
            },
            {
              name: "- Montana",
              href: "/united-states/montana",
            },
            {
              name: "- Nevada",
              href: "/united-states/nevada",
            },
            {
              name: "- Southern USA",
              href: "/united-states/southern-usa",
            },
            {
              name: "- Utah",
              href: "/united-states/utah",
            },
            {
              name: "- Wyoming",
              href: "/united-states/wyoming",
            },
          ],
        },
        {
          name: "Polar",
          href: "/polar",
          children: [
            {
              name: "Antarctica",
              href: "/antarctica",
            },
            {
              name: "Arctic",
              href: "/arctic",
            },
            {
              name: "Greenland",
              href: "/greenland",
            },
          ],
        },
        {
          name: "South America",
          href: "/south-america",
          children: [
            {
              name: "Argentina",
              href: "/argentina",
            },
            {
              name: "Bolivia",
              href: "/bolivia",
            },
            {
              name: "Brazil",
              href: "/brazil",
            },
            {
              name: "Chile",
              href: "/chile",
            },
            {
              name: "Colombia",
              href: "/colombia",
            },
            {
              name: "Ecuador",
              href: "/ecuador",
            },
            {
              name: "Falkland Islands",
              href: "/falkland-islands",
            },
            {
              name: "Galapagos Islands",
              href: "/galapagos-islands",
            },
            {
              name: "Machu Picchu",
              href: "/peru/machu-picchu",
            },
            {
              name: "Patagonia",
              href: "/patagonia",
            },
            {
              name: "Peru",
              href: "/peru",
            },
            {
              name: "Uruguay",
              href: "/uruguay",
            },
          ],
        },
        {
          name: "All destinations",
          href: "/destinations",
        },
      ],
    },
    {
      name: "Ways to travel",
      href: "/theme",
      children: [
        {
          name: "Themes",
          href: "/theme",
          children: [
            {
              name: "18 to 35s",
              href: "/18-35s",
            },
            {
              name: "Cruises",
              href: "/cruising",
            },
            {
              name: "Cycling",
              href: "/cycling",
            },
            {
              name: "Expeditions",
              href: "/expeditions",
            },
            {
              name: "Family",
              href: "/family",
            },
            {
              name: "Festivals",
              href: "/festivals",
            },
            {
              name: "Food",
              href: "/food",
            },
            {
              name: "Multi-active",
              href: "/multi-active-adventures",
            },
            {
              name: "Polar",
              href: "/polar",
            },
            {
              name: "Rail",
              href: "/rail",
            },
            {
              name: "Tailor-Made ",
              href: "/tailor-made",
            },
            {
              name: "Walking & trekking",
              href: "/walking",
            },
            {
              name: "Wildlife",
              href: "/wildlife",
            },
            {
              name: "Winter",
              href: "/winter-tours",
            },
            {
              name: "Women's Expeditions",
              href: "/womens-expeditions",
            },
            {
              name: "View all themes",
              href: "/theme",
            },
          ],
        },
        {
          name: "Styles",
          href: "/trip-styles",
          children: [
            {
              name: "Premium",
              href: "/premium",
            },
            {
              name: "Comfort",
              href: "/comfort",
            },
            {
              name: "Original",
              href: "/original",
            },
            {
              name: "Basix",
              href: "/basix",
            },
            {
              name: "About travel styles",
              href: "/trip-styles",
            },
          ],
        },
      ],
    },
    {
      name: "Deals",
      href: "/travel-deals",
      children: [
        {
          name: "Search all trips on sale",
          href: "/search?page=1&sort=relevance&onSale=true",
        },
        {
          name: "See all deals",
          href: "/travel-deals",
        },
      ],
    },
    {
      name: "About",
      href: "/about",
      children: [
        {
          name: "Who we are",
          href: "/about",
        },
        {
          name: "Our purpose",
          href: "/purpose",
        },
        {
          name: "Our stories",
          href: "/stories",
        },
        {
          name: "Books by Intrepid",
          href: "/the-intrepid-list",
        },
      ],
    },
  ];
};
